import React from "react";
import SEO from "../common/SEO";
import HeaderOne from "../common/header/HeaderOne";
import HeaderTopBar from "../common/header/HeaderTopBar";
import AboutFour from "../elements/about/AboutFour";
import AdvanceTabTwo from "../elements/advancetab/AdvanceTabTwo";
import CalltoActionFive from "../elements/calltoaction/CalltoActionFive";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import FooterOne from "../common/footer/FooterOne";
import Copyright from "../common/footer/Copyright";
document.body.classList.toggle("light");
const Consulting = () => {
  return (
    <>
      <SEO title="Melos Event" />
      <main className="page-wrapper">
        {/* <HeaderTopBar /> */}
        <HeaderOne btnStyle="btn-small" HeaderSTyle="header-transparent" />

        {/* Start Slider area  */}
        <div
          className="slider-area slider-style-2 variation-2  height-850 bg_image"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bride.png)`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-xl-6">
                <div className="inner text-start">
                  <h1 className="title display-one">
                    Listen to your heart, <br /> Follow your own style, <br />{" "}
                    then hire us to bring it to life.
                  </h1>
                  <h6 className="tag-title">WE SERVE SOUVENIRS</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider area  */}

        <AboutFour image="./images/about/contact-image.jpg" />

        <Separator />

        {/* Start Elements Area  */}
        <div className="rwt-advance-tab-area rn-section-gap">
          <div className="container">
            <div className="row mb--40">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Why Us?"
                  title="Wonder why you should choose us?"
                  description=""
                />
              </div>
            </div>
            <AdvanceTabTwo />
          </div>
        </div>
        {/* End Elements Area  */}

        <Separator />

        {/* Start Elements Area  */}
        <div className="rwt-callto-action-area rn-section-gapBottom">
          <div className="wrapper">
            <CalltoActionFive />
          </div>
        </div>
        {/* End Elements Area  */}

        <FooterOne />
        <Copyright />
      </main>
    </>
  );
};

export default Consulting;
