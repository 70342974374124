import React from "react";

const Copyright = () => {
  return (
    <div className="copyright-area copyright-style-one">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-4 col-md-4 col-sm-12 col-12">
            <div className="copyright-left">
              <ul className="ft-menu link-hover">
                <li>
                  <a href="/contact">Contact Us</a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12 col-12">
            <div className="copyright-right text-center">
              <p className="copyright-text">
                © MelosEvent {new Date().getFullYear()}
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12 col-12">
            <div className="copyright-right text-end">
              <p className="copyright-text">
                Built by{" "}
                <span style={{ fontStyle: "italic" }}>
                  <a
                    href="https://www.fabriq.rw"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Fabriq
                  </a>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Copyright;
