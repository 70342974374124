import React from "react";
import VideoTwo from "../video/VideoTwo";
import { FiCheck } from "react-icons/fi";

const PopupData = [
  {
    id: "01",
    image: "./images/bg/bg-image-4.jpg",
    popupLink: ["https://www.youtube.com/watch?v=cgOmCTx6LZ0"],
  },
];

const AboutFour = ({ image }) => {
  return (
    <div className="about-area about-style-4 rn-section-gap">
      <div className="container">
        <div className="row row--40 align-items-center">
          <div className="col-lg-6">
            {PopupData.map((item) => (
              <div className="video-btn" key={item.id}>
                <VideoTwo imagename={`${image}`} galleryItem={item} />
              </div>
            ))}
          </div>
          <div className="col-lg-6 mt_md--40 mt_sm--40">
            <div className="content">
              <div className="inner">
                <h4 className="title">
                  Melos Event specializes in <br />
                  <strong>Wedding planning</strong> and <br />
                  <strong>Corporate events.</strong> and we believe that:
                </h4>
                <ul className="feature-list">
                  <li>
                    <div className="icon">
                      <FiCheck />
                    </div>
                    <div className="title-wrapper">
                      <h4 className="title">
                        A wedding should reflect the couple’s personality and be
                        a day to remember forever
                      </h4>
                      <p className="text">
                        Thus, we work with the couple through every step and
                        every little detail in order to produce a unique and
                        personalized wedding.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <FiCheck />
                    </div>
                    <div className="title-wrapper">
                      <h4 className="title">
                        A corporate event should be represent the organization's
                        culture and values, and should be hussle-free for the
                        organization's team.
                      </h4>
                      <p className="text">
                        We take the load of every little detail, so you can
                        focus on the outcome of the event for you and your team.
                      </p>
                    </div>
                  </li>
                </ul>
                <div className="about-btn mt--30">
                  <a className="btn-default" href="#">
                    See our portfolio
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFour;
